frappe.provide("frappe.views");

frappe.views.ListView = class CustomListView extends frappe.views.ListView {
    // Get the complete row HTML by combining left, right, and custom button sections
    get_list_row_html(doc) {
        const left_html = this.get_left_html(doc);
        const right_html = this.get_right_html(doc);
        const custom_button_html = this.get_custom_button_html(doc);
        
        return this.get_list_row_html_skeleton(left_html, right_html + custom_button_html);
    }

    // Define the left HTML content (Placeholder, replace with actual logic)
    // get_left_html(doc) {
    //     return `<span>${doc.name}</span>`;
    // }

    // Define the list row skeleton
    get_list_row_html_skeleton(left = "", right = "") {
        return `
            <div class="list-row-container" tabindex="1">
                <div class="level list-row">
                    <div class="level-left ellipsis">
                        ${left}
                    </div>
                    <div class="level-right text-muted ellipsis">
                        ${right}
                    </div>
                </div>
            </div>
        `;
    }

    // Define the custom button HTML separately for reusability
    get_custom_button_html(doc) {
        let action_button = "";
    
        if (doc.docstatus === 0 || doc.docstatus === 2) {
            // Show Delete button if document is Draft (0) or Cancelled (2)
            action_button = `
                <button class="btn btn-xs btn-danger custom-row-btn" data-name="${doc.name}" data-action="delete">
                    ${__("Delete")}
                </button>
            `;
        } else if (doc.docstatus === 1) {
            // Show Cancel button if document is Submitted (1)
            action_button = `
                <button class="btn btn-xs btn-secondary custom-row-btn" data-name="${doc.name}" data-action="cancel">
                    ${__("Cancel")}
                </button>
            `;
        }
    
        return `
            <div style="display: flex; gap: 5px;">
                <button class="btn btn-xs btn-warning custom-row-btn" data-name="${doc.name}" data-action="view">
                    ${__("View")}
                </button>
                <button class="btn btn-xs btn-primary custom-row-btn" data-name="${doc.name}" data-action="edit">
                    ${__("Edit")}
                </button>
                ${action_button} <!-- Show either Delete or Cancel button -->
            </div>
        `;
    }
    
    
    
    

    // Override setup_events to bind events after the parent method call
    setup_events() {
        super.setup_events();
        this.bind_custom_button_click();
    }

    // Event binding for custom button click
    bind_custom_button_click() {
        this.$result.on("click", ".custom-row-btn", (event) => {
            const name = $(event.currentTarget).data("name");
            const action = $(event.currentTarget).data("action");
    
            if (name && action) {
                this.handle_custom_button_click(name, action);
            }
        });
    }
    

    handle_custom_button_click(name, action) {
        switch (action) {
            case "view":
                this.redirect_to_form(name);
                break;
            case "edit":
                this.redirect_to_edit(name);
                break;
            case "delete":
                this.confirm_delete(name);
                break;
            case "cancel":
                this.confirm_cancel(name,action);
                break;
            default:
                frappe.msgprint(__("Unknown action"));
        }
    }
    
    // Redirect to the document's form page
    redirect_to_form(name) {
        frappe.set_route("Form", this.doctype, name);
    }
    
    // Redirect to edit mode
    redirect_to_edit(name) {
        frappe.set_route("Form", this.doctype, name, "Edit");
    }
    
    // Confirm and delete document
    confirm_delete(name) {
        frappe.confirm(
            __("Are you sure you want to delete {0}?", [name]),
            () => {
                frappe.call({
                    method: "frappe.client.delete",
                    args: { doctype: this.doctype, name: name },
                    callback: (response) => {
                        if (!response.exc) {
                            frappe.show_alert({ message: __("Deleted {0}", [name]), indicator: "red" });
                            this.refresh();
                        }
                    }
                });
            }
        );
    }
    
    // Confirm and cancel document
    confirm_cancel(name,action) {
        console.log(action)
        frappe.confirm(
            __("Are you sure you want to cancel {0}?", [name]),
            () => {
                frappe.call({
                    method: "frappe.model.workflow.apply_workflow",
                    args: {
                        doc: {
                            doctype: this.doctype,
                            name: name,
                            workflow_state: "Cancelled"
                        },
                        action: action
                    },
                    callback: (response) => {
                        if (!response.exc) {
                            frappe.show_alert({ message: __("Cancelled {0}", [name]), indicator: "orange" });
                            this.refresh();
                        }
                    }
                });
            }
        );
    }
    };
